<template>
    <div class="container">
        <div>
            <el-card class="box-card" shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>终端查询</span>
                    </div>
                </template>
                <div class="card-content">
                    <el-form :inline="true" :model="searchInfo" label-width="100px" label-position="right">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="商户号:" style="width: 100%;">
                                    <el-select v-model="searchInfo.merchant_id" placeholder="请输入商户号或昵称" filterable clearable
                                        style="width: 90%;">
                                        <el-option v-for="option in optionsMerchant" :key="option.id"
                                            :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item label="门店号:" style="width:292px">
                                    <el-input v-model="searchInfo.store_id" placeholder="请输入门店号" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="终端状态:">
                                    <el-select v-model="searchInfo.status" filterable placeholder="请选择" clearable>
                                        <el-option :value="1" label="已启用" />
                                        <el-option :value="0" label="未启用" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="代理商ID:" style="width:292px">
                                    <el-input v-model="searchInfo.agent_id" placeholder="请输入代理商ID" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="终端ID:" style="width:292px">
                                    <el-input v-model="searchInfo.id" placeholder="请输入终端ID" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-button type="primary" @click="search">
                                    <span>搜索</span>
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
        </div>

        <div class="mian">
            <div class="addNewTerminal">
                <el-button type="primary" @click="outerVisible = true">新增终端</el-button>
                <el-dialog v-model="outerVisible" title="新增终端" center>
                    <template #default>
                        <el-form :inline="true" :model="serchInline" :rule="rules">
                            <el-card shadow="never" class="card-border">
                                <template #header>
                                    <div class="card-header">
                                        <span>新增终端</span>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <el-form-item style="width:292px">
                                        <template v-slot:label>
                                            <span class="required-label">终端名称</span><span style="color: red">*</span>
                                        </template>
                                        <el-input v-model="serchInline.name" placeholder="请输入" />
                                    </el-form-item>
                                    <el-form-item label="选择商户" style="width: 70%;">
                                        <el-select v-model="serchInline.merchant_id" placeholder="请选择" filterable clearable
                                            style="width: 100%;"
                                            @change="getDevicesOptions({ merchant_id: serchInline.merchant_id })">
                                            <el-option v-for="option in optionsMerchant" :key="option.id"
                                                :label="option.label" :value="option.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="收银设备" style="width: 70%;">
                                        <el-select v-model="serchInline.device_id" placeholder="请选择" filterable clearable
                                            style="width: 100%;">
                                            <el-option v-for="option in options" :key="option.id" :label="option.label"
                                                :value="option.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否启用" style="width:292px">
                                        <el-switch v-model="serchInline.status" active-value="1"
                                            inactive-value="0"></el-switch>
                                    </el-form-item>
                                </div>
                            </el-card>
                        </el-form>
                    </template>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="outerVisibles()">取消</el-button>
                            <el-button type="primary" @click="addNewTerminal(serchInline)">
                                确定
                            </el-button>
                        </div>
                    </template>
                </el-dialog>
            </div>
            <el-card shadow="never" class="card-border">
                <template #header>
                    <div class="card-header">
                        <span>终端列表</span>
                    </div>
                </template>
                <cTable ref="deviceTable" :tableDataUrl="deviceTableDataUrl" :border="true" :stripe="true"
                    :defaultSort="{ prop: 'columnName', order: 'ascending' }" :isShowPagination="true" :isLoadData="true"
                    @rowClassName="yourRowClassNameHandler" @selectionRows="yourSelectionRowsHandler">
                    <el-table-column prop="id" label="终端ID" />
                    <el-table-column prop="name" label="名称" />
                    <el-table-column prop="active_code" label="激活码" />
                    <el-table-column label="设备类型">
                        <template #default="scope">
                            <text v-if="scope.row.device == null"></text>
                            <text v-else>{{ scope.row.device.device_category.name }}/{{ scope.row.device.device_type.name
                            }}</text>
                        </template>
                    </el-table-column>
                    <el-table-column prop="device.serial_number" label="收银设备" width="160" />
                    <el-table-column prop="merchant.no" label="商户号" />
                    <el-table-column prop="store.name" label="门店" />
                    <el-table-column prop="merchant.merchant_alias" label="商户简称" />
                    <el-table-column prop="actived_at" label="激活时间" />
                    <el-table-column prop="status" label="启用状态">
                        <template #default="scope">
                            <text v-if="scope.row.status === 1">已启用</text>
                            <text v-else>未启用</text>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operator" label="operator" />
                    <el-table-column label="操作" width="200">
                        <template #default="scope">
                            <div style="display: flex; flex-direction: row;">
                                <el-button :icon="Search" circle @click="getTerminalDetails(scope.row.id)" />
                                <el-button type="primary" :icon="Edit" circle @click="handleUpadteTermina(scope.row.id)" />
                                <!-- <el-button type="danger" :icon="Delete" circle @click="open(scope.row.id)" /> -->
                            </div>

                        </template>
                    </el-table-column>
                </cTable>
                <!-- <div class="pagination">
                    <el-pagination :page-size="pagination.perPage" :pager-count="7" layout="prev, pager, next"
                        :total="pagination.total" @current-change="handleSizeChange">
                    </el-pagination>
                </div> -->
            </el-card>

            <el-dialog v-model="setTerminalVisible" title="修改终端信息" center>
                <template #default>
                    <el-form :inline="true" :model="terminalDetails">
                        <el-card shadow="never" class="card-border">
                            <div class="card-content">
                                <el-form-item style="width:292px">
                                    <template v-slot:label>
                                        <span class="required-label">终端名称</span><span style="color: red">*</span>
                                    </template>
                                    <el-input v-model="terminalDetails.name" placeholder="请输入" />
                                </el-form-item>
                                <el-form-item label="选择商户" style="width: 70%;">
                                    <el-select v-model="terminalDetails.merchant.id" placeholder="请选择" filterable disabled
                                        style="width: 100%;"
                                        @change="getDevicesOptions({ merchant_id: terminalDetails.merchant.id })">
                                        <el-option v-for="option in optionsMerchant" :key="option.id" :label="option.label"
                                            :value="option.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="收银设备" style="width: 70%;">
                                    <el-select v-model="terminalDetails.device_id" placeholder="请选择" filterable clearable
                                        style="width: 100%;">
                                        <el-option v-for="option in options" :key="option.id" :label="option.label"
                                            :value="option.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="是否启用" style="width:292px">
                                    <el-switch v-model="terminalDetails.status" active-value="1"
                                        inactive-value="0"></el-switch>
                                </el-form-item>
                            </div>
                        </el-card>
                    </el-form>
                </template>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="setTerminalVisible = false">取消</el-button>
                        <el-button type="primary" @click="updateTerminal()">
                            确定
                        </el-button>
                    </div>
                </template>
            </el-dialog>

            <el-dialog v-model="getTerminalDetail" title="终端详情" center>
                <template #default>
                    <el-form :inline="true">
                        <el-card shadow="never" class="card-border">
                            <div class="card-content">
                                <div class="terminalDetails">
                                    <span><text>终端名称:</text><text>{{ terminalDetails.name }}</text></span>
                                    <span><text>operator:</text><text>{{ terminalDetails.operator }}</text></span>

                                </div>
                                <div class="terminalDetails">
                                    <span><text>终端ID:</text><text>{{ terminalDetails.id }}</text></span>
                                    <span><text>代理商ID:</text><text>{{ terminalDetails.agent_id }}</text></span>


                                </div>
                                <div class="terminalDetails">
                                    <span><text>创建时间:</text><text>{{ terminalDetails.created_at.slice(0, 10) }}&nbsp;{{
                                        terminalDetails.created_at.slice(12, 19) }}</text></span>
                                    <span><text>更新时间:</text><text>{{ terminalDetails.updated_at.slice(0, 10) }}&nbsp;{{
                                        terminalDetails.updated_at.slice(12, 19) }}</text></span>
                                </div>
                                <div class="terminalDetails">
                                    <span>
                                        <text>商户名称:</text><text>{{ merchant_name }}</text>
                                    </span>
                                    <span><text>设备ID:</text><text>{{ terminalDetails.device_id }}</text></span>
                                </div>
                            </div>
                        </el-card>
                    </el-form>
                </template>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="getTerminalDetail = false">返回</el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance } from "vue"
// import { Delete, Edit, Search } from '@element-plus/icons-vue'
import { Edit, Search } from '@element-plus/icons-vue'
// import { ElNotification, ElMessage, ElMessageBox } from 'element-plus'
import { ElNotification } from 'element-plus'
import cTable from '@/components/CustomTable'
const { proxy } = getCurrentInstance()

const outerVisible = ref(false)
const setTerminalVisible = ref(false)
const getTerminalDetail = ref(false)
const terminalId = ref('')
const searchInfo = ref({})
const merchant_name = ref('')
const deviceTable = ref(null)
const deviceTableDataUrl = proxy.$api.terminals.getTerminalList
const serchInline = reactive({
    device_id: '',
    name: '',
    status: '1',
    merchant_id: '',
})
const rules = ref({
    name: [
        { required: true, message: '请输入', trigger: 'blur' }
    ],
})

const outerVisibles = () => {
    outerVisible.value = false
    serchInline.name = ''
    serchInline.merchant_id = ''
    serchInline.device_id = ''
}

const options = ref([])
const getDevicesOptions = async (data) => {
    await proxy.$api.dict.getDeviceDict(data).then(res => {
        if (res.status == 200) {
            options.value = res.data.data
            console.log(options.value, data, 'freddy')
        }
    })
}

const optionsMerchant = ref([])
const getOptionsMerchant = async (data) => {
    await proxy.$api.dict.getMerchantDict(data).then(res => {
        if (res.status == 200) {
            optionsMerchant.value = res.data.data
        }
    })
}


//新增终端
const addNewTerminal = async (data) => {
    await proxy.$api.terminals.addNewTerminal(data).then(res => {
        if (res.status == 200) {
            ElNotification({ title: 'Success', message: '新增成功', type: 'success', })
            outerVisible.value = false
            search()
        }else {
            ElNotification({
                title: 'Error',
                message: '新增失败',
                type: 'error'
            })
        }
    })
}

//更新终端
const updateTerminal = async () => {
    await proxy.$api.terminals.updateTerminal(terminalId.value, {
        name: terminalDetails.value.name,
        device_id: terminalDetails.value.device_id,
        status: terminalDetails.value.status
    }).then(res => {
        if (res.status == '200') {
            console.log(1111);
            ElNotification({
                title: 'Success',
                message: '更新成功',
                type: 'success'
            });
            search()
            setTerminalVisible.value = false;
        } else {
            ElNotification({
                title: 'Error',
                message: '更新失败',
                type: 'error'
            })
        }
    })
}



//获取终端详细信息
const terminalDetails = ref({})
const getTerminalDetails = async (data) => {

    await proxy.$api.terminals.getTerminalDetails(data).then(res => {
        if (res.status == 200) {
            getTerminalDetail.value = true
            terminalDetails.value = res.data.data
            if (res.data.data.merchant != null) {
                merchant_name.value = res.data.data.merchant.merchant_name
            } else {
                merchant_name.value = ''
            }
        }
    })
}




//获取更新状态
const handleUpadteTermina = async (data) => {
    await proxy.$api.terminals.getTerminalDetails(data).then(res => {
        if (res.status == 200) {
            terminalId.value = data
            setTerminalVisible.value = true
            terminalDetails.value = res.data.data
            getDevicesOptions({ merchant_id: terminalDetails.value.merchant.id })
            console.log(terminalDetails.value.merchant_id);
            if (res.data.data.status === 1) {
                terminalDetails.value.status = "1"
            } else {
                terminalDetails.value.status = "0"
            }

        }
    })
}
const search = () => {
    deviceTable.value.setParams(searchInfo)
}
onMounted(() => {
    getOptionsMerchant()
})

</script>

<style lang="less" scoped>
.container {
    width: 100%;
    box-sizing: border-box;

    .box-card {
        border-top: 5px solid #e7e7e7;
    }

    .card-header {
        span {
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.mian {
    .card-border {
        border-top: 5px solid #e7e7e7;

        .terminalDetails {
            display: flex;

            span {
                flex: 1;
                margin-top: 20PX;
                font-size: 16PX;

                text {
                    margin-left: 10px;
                }
            }
        }
    }

    .addNewTerminal {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
